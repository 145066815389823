import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import { useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useMutation } from "@apollo/client";
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import { Box, Card, CardContent, CardMedia, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { cartAddItem, imageBasketAddItem } from "../../actions";
import {
  INITIALIZE_PRODUCT_DOWNLOAD,
  SAVE_IMAGE_BASKET,
} from "../../graphql/mutations";

import { getSelectedSiteOrderGroupId } from "../../helpers/selectors";
import { useSnackbar } from "../../hooks";
import ChipComponent from "../common/Chip";
import { ProductModal } from ".";

const cardContentPadding = "4px 6px 4px 4px";

const useStyles = ({ cardMediaSize }) => makeStyles(theme => ({
  card: {
    border: "1px solid",
    borderColor: theme.palette.background.contrastGray,
    borderRadius: "4px",
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.05)",
    display: "flex",
    flexDirection: "column",
    padding: "14px",
    //paddingBottom: `${cardContentPadding}`,
    textAlign: "center",
    width: `${cardMediaSize}`,
  },
  cardContent: {
    padding: `${cardContentPadding}`,
    paddingBottom: 0,
    "&:last-child": {
      minHeight: "48px",
      paddingBottom: 0,
    },
    "& .MuiChip-root": {
      backgroundColor: theme.palette.secondary.dark,
      height: 18,
      marginRight: 3,
    },
    "& .MuiChip-labelSmall": {
      paddingLeft: 6,
      paddingRight: 6,
    },
  },
  cardActions: {
    backgroundImage: "linear-gradient(transparent, #00000040)",
    bottom: 0,
    padding: 5,
    position: "absolute",
    width: "100%",
    zIndex: 10,
  },
  cardActionsBackground: {
    backgroundImage: "linear-gradient(transparent, #00000010)",
    bottom: 0,
    padding: 20,
    position: "absolute",
    width: "100%",
  },
  fileNameText: {
    fontSize: 12,
    fontWeight: 400,
    //color: theme.palette.component.productBoxColor,
    color: "#999999",
    paddingBottom: 0,
    wordBreak: "break-all",
  },
  hashTagText: {
    fontSize: 9,
    minHeight: 32,
    color: theme.palette.primary.main,
  },
  iconButton: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    color: theme.palette.common.white,
    fontSize: 14,
    margin: "0px 5px",
    padding: 6,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      color: theme.palette.common.white,
    },
  },

  consentIcon: {
    fontSize: 14,
    backgroundColor: "rgba(0, 0, 0, 0.4)",    
    margin: "0px 5px",
    padding: 6    
  },

  mediaHover: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    opacity: 0,
    transition: "visibility 0s, opacity 0.15s linear",
    visibility: "hidden",
  },
  media: {
    backgroundSize: "cover",
    height: `${cardMediaSize}`,
    position: "relative",
    width: "auto",
    "&:hover": {
      cursor: "pointer",
      "& > div": {
        opacity: 1,
        visibility: "visible",
      },
    },
  },
  tooltipDesc: {
    color: theme.palette.text.primary,
    fontSize: 11,
    marginBottom: 5,
  },
  tooltipStat: {
    color: theme.palette.text.primary,
    fontSize: 12,
    fontWeight: 600,
  },
  tooltipStatTitle: {
    color: theme.palette.text.primary,
    fontSize: 11,
    whiteSpace: "nowrap",
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ddd",
  },
  arrow: {
    color: theme.palette.common.white,
  },
  tagsWrap: {
    marginTop: 10,
    textAlign: "left",
  },
}));

const DocumentViewItem = ({
  cardMediaSize = "208px",
  cartAddItem,
  imageBasketAddItem,
  imageBasketItems,
  orderGroupId,
  product,
  showProductModalOnOpen,
}) => {
  const classes = useStyles({ cardMediaSize })();
  const alert = useAlert();
  const snackbar = useSnackbar();

  const {
    description,
    minimumQuantity,
    name,
    statistics,
    tags = [],
    thumbnailImageUri,
    userPermissions,
    consented
  } = product;

  const { addToImageCart, download, order, share } = userPermissions || {};
  const { downloadCount, shareCount, viewCount } = statistics;

  const isImage = addToImageCart ?? false;

  const [openProductModal, setOpenProductModal] = useState(showProductModalOnOpen);
  const [quantity, setQuantity] = useState(minimumQuantity);

  const [downloadProduct] = useMutation(INITIALIZE_PRODUCT_DOWNLOAD);

  const [saveImageBasket] = useMutation(SAVE_IMAGE_BASKET, {
    onCompleted: resultData => {
      if (resultData && resultData.addToImageBank) {
        alert.success(<FormattedMessage id="product.addedToImageBasket" />);
        const {
          imageName,
          itemId,
          productId,
          settings,
        } = resultData.addToImageBank;
        imageBasketAddItem({
          item: { imageName, itemId, productId, settings },
        });
      }
    },
  });

  const handleAddToImageBasket = () => {
    const isDuplicate = imageBasketItems.some(x => {
      return x.productId === product.id;
    });

    if (imageBasketItems) {
      if (isDuplicate) {
        snackbar.workspaceError(
          <FormattedMessage id="product.duplicateItem" />
        );
      } else {
        saveImageBasket({
          variables: {
            orderGroupId,
            productId: product.id,
          },
        });
      }
    } else {
      snackbar.workspaceError(<FormattedMessage id="product.failedToCart" />);
    }
  };

  const handleAddProduct = () => {
    if (addToImageCart) {
      handleAddToImageBasket();
    } else {
      handleOpenProductModal();
    }
  };

  const handleCloseProductModal = () => {
    setOpenProductModal(false);
  };

  const handleDownloadProduct = async () => {
    try {
      const res = await downloadProduct({
        variables: { productId: product.id },
      });
      if (res && res.data && res.data.initProductDownload) {
        const downloadUrl = res.data.initProductDownload;
        saveAs(downloadUrl);
      } else {
        console.log("Download Failure");
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const handleOpenProductModal = () => {
    setOpenProductModal(true);
  };

  const ActionIconButton = ({ icon, onClick }) => (
    <IconButton
      className={classes.iconButton}
      onClick={onClick}
    >
      <i className={icon} />
    </IconButton>
  );

  /*
  import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
  import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
  import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
  */

  const IconConsented = () => {
    let icon = null;
    let tooltipText = '';

    switch (consented) {
      case "NOT_SENT":
        return null;
      case "SENT":
        icon = <GppMaybeOutlinedIcon sx={{ color: "lightgray", position: "absolute", top: 0, right: 0 }} />;
        tooltipText = "Begäran om samtycke skickad";
        break;
      case "DENIED":
        icon = <GppBadOutlinedIcon sx={{ color: "red", position: "absolute", top: 0, right: 0 }} />;
        tooltipText = "Samtycke avslaget";
        break;
      case "CONSENTED":
        icon = <GppGoodOutlinedIcon sx={{ color: "green", position: "absolute", top: 0, right: 0 }} />;
        tooltipText = "Samtycke finns";
        break;
      default:
        return null;
    }

    return (
      <Tooltip title={tooltipText}>
        {icon}
      </Tooltip>
    );
  };

  const Actions = () => (
    <Box className={classes.cardActions} disableSpacing>
      <Tooltip
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        placement="top"
        title={<InfoTooltip />}
      >
        <IconButton className={classes.iconButton} onClick={handleOpenProductModal} >
          <i className={"fas fa-info-circle"} />
        </IconButton>
      </Tooltip>
      {share &&
        <ActionIconButton icon="fa fa-share-alt" onClick={() => { }} />
      }
      {download &&
        <ActionIconButton icon="fas fa-download" onClick={handleDownloadProduct} />
      }
      {(isImage || order) &&
        <ActionIconButton icon="fas fa-shopping-cart" onClick={handleAddProduct} />
      }
      

    </Box>
  );

  const CardMediaBackgound = () => (
    <Box className={classes.cardActionsBackground} />
  );

  const InfoTooltip = () => (
    <>
      <Typography color="inherit" className={classes.tooltipDesc}>
        {description}
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={4} align="center">
          <Typography color="inherit" className={classes.tooltipStatTitle}>
            <FormattedMessage id="product.downloads" />
          </Typography>
          <Typography color="inherit" className={classes.tooltipStat}>
            {downloadCount}
          </Typography>
        </Grid>
        <Grid item md={4} align="center">
          <Typography color="inherit" className={classes.tooltipStatTitle}>
            <FormattedMessage id="product.views" />
          </Typography>
          <Typography color="inherit" className={classes.tooltipStat}>
            {viewCount}
          </Typography>
        </Grid>
        <Grid item md={4} align="center">
          <Typography color="inherit" className={classes.tooltipStatTitle}>
            <FormattedMessage id="product.shares" />
          </Typography>
          <Typography color="inherit" className={classes.tooltipStat}>
            {shareCount}
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Card className={classes.card}>
      
      <CardMedia
        className={classes.media}
        image={thumbnailImageUri}
        onClick={handleOpenProductModal}
      >        
        <Box className={classes.mediaHover}>
          <IconConsented />
          <IconButton className={classes.iconButton}>
            <i className="fa fa-search-plus" />
          </IconButton>
          <Actions />
          
        </Box>
        <CardMediaBackgound />
      </CardMedia>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.fileNameText}>{name}</Typography>
        {false &&
          <Box className={classes.tagsWrap}>
            {tags.map(t => (
              <ChipComponent
                size="small"
                color="light"
                label={t.tagName}
                className={classes.tag}
                classes={{
                  root: classes.chipRoot,
                  deleteIcon: classes.icon,
                }}
              />
            ))}
          </Box>
        }
      </CardContent>
      <ProductModal
        cartAddItem={cartAddItem}
        handleAddImageBasket={handleAddToImageBasket}
        handleClose={handleCloseProductModal}
        isImage={isImage}
        open={openProductModal}
        product={product}
        quantity={quantity}
        setQuantity={setQuantity}
      />
    </Card>
  );
};

DocumentViewItem.defaultProps = {
  orderGroupId: null,
  showProductModalOnOpen: false,
};

DocumentViewItem.propTypes = {
  imageBasketAddItem: PropTypes.func.isRequired,
  imageBasketItems: PropTypes.arrayOf(
    PropTypes.shape({
      imageName: PropTypes.string,
      itemId: PropTypes.number,
      productId: PropTypes.string,
      settings: PropTypes.string,
    })
  ).isRequired,
  orderGroupId: PropTypes.number,
  product: PropTypes.shape({
    cartAddItem: PropTypes.func.isRequired,
    description: PropTypes.string,
    id: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    minimumQuantity: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    quantity: PropTypes.number,
    statistics: PropTypes.shape({
      downloadCount: PropTypes.number,
      shareCount: PropTypes.number,
      viewCount: PropTypes.number,
    }),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        tagId: PropTypes.number.isRequired,
        tagName: PropTypes.string.isRequired,
      })
    ).isRequired,
    thumbnailImageUri: PropTypes.string.isRequired,
    userPermissions: PropTypes.shape({
      addToImageCart: PropTypes.bool,
      download: PropTypes.bool,
      order: PropTypes.bool,
      share: PropTypes.bool,
    }),
  }).isRequired,
  showProductModalOnOpen: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    imageBasketItems: state.api.imageBasket.imageBasketItems,
    orderGroupId: getSelectedSiteOrderGroupId(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      imageBasketAddItem,
      cartAddItem,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentViewItem);
