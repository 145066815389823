import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NoImage from "../../../../assets/img/noimage-136.png";
import ResourceImageItem from "./ResourceImageItem";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 5,
  },
  gridItem: {},
  noImageContainer: {
    paddingTop: 10,
  },
}));

const ResourcesList = ({ handleImageSelect, imageField, resources }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item md={4} sm={4} xs={3} className={classes.gridItem}>
          <ResourceImageItem
            item={{
              id: null,
              thumbnailImageUri: NoImage,
              name: intl.formatMessage({
                id: "common.noImage",
              }),
            }}
            handleImageSelect={handleImageSelect}
            selected={!imageField || imageField.id === null}
          />
        </Grid>
        {resources &&
          resources.length > 0 &&
          resources.map(x => {
            return (
              <Grid
                item
                key={x.id}
                md={4}
                sm={4}
                xs={3}
                className={classes.gridItem}
              >
                <ResourceImageItem
                  item={x}
                  handleImageSelect={handleImageSelect}
                  selected={
                    imageField && imageField.id && imageField.id === x.id
                  }
                />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

ResourcesList.defaultProps = {
  imageField: {},
  resources: [],
};

ResourcesList.propTypes = {
  handleImageSelect: PropTypes.func.isRequired,
  imageField: PropTypes.shape({}),
  resources: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ResourcesList;
