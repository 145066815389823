import React from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Box, Grid, Typography, Icon } from "@mui/material";
import clsx from "clsx";
import Avatar from "../../../common/Avatar";

const useStyles = makeStyles(theme => ({
  authorContainer: {
    flexWrap: "noWrap",
  },
  avatarMargin: {
    marginRight: 0,
  },
  authorName: {
    fontSize: 12,
    fontWeight: 400,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  ingress: {
    paddingTop: "5px",
    fontSize: 12,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  newsTitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 5,
    marginBottom: 5,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  newsItemContainer: {
    marginTop: 0,
    marginBottom: 5,
    padding: "10px 10px 15px 10px",
    background: props => props.contentBG || theme.palette.background.paper,
  },
  titleContainer: {
    position: "relative",
  },
  date: {
    fontSize: 11,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  joinNowContainer: {
    marginTop: 5,
  },
  joinNow: {
    fontSize: 10,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  leftTitleBar: {
    position: "absolute",
    left: -10,
    top: 0,
    height: "100%",
    width: 2,
    background: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  leftTitleBarLarge: {
    position: "absolute",
    left: -6,
    top: 0,
    height: "100%",
    width: 2,
    background: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  arrowIcon: {
    fontSize: 12,
    marginLeft: 3,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  workspace: {
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
    fontSize: 10,
  },
  mainImg: {
    width: "100%",
    borderRadius: 0,
    paddingTop: "0px",
    height: "150px",
    objectFit: "cover",
    objectPosition: " 20% 10%",
  },
  newsSubItems: {
    borderLeft: props => `1px solid ${props.dividerColor || "#dee2e6"}`,
  },
  clamped: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
  },
  newsBig: {
    fontSize: 18,
  },
  imageContainer: {
    marginRight: 8,
  },
}));

const News = ({ news, customStyles, onClickReadMore, idx }) => {
  const classes = useStyles(customStyles);

  const {
    title,
    author,
    avatarUrl,
    leadText,
    createdDate,
    id,
    image,
    site,
    newsPost,
  } = news || {};

  const showSubImage = image && idx !== 0;
  const showMainImage = image && idx === 0;

  const handleOnClick = (newspost) => {

    if (onClickReadMore) {
      onClickReadMore(newspost);
    }
  }

  return (
    <Grid
      item
      className={/*idx !== 0 &&*/ classes.newsSubItems}
      xs={idx === 0 ? 6 : 3}
    >
      {showSubImage && (
        <Grid container>
          <img src={image} alt={title} className={classes.mainImg} />
        </Grid>
      )}
      <Grid container className={classes.newsItemContainer}>
        {idx !== 0 && (
          <>
            <Grid item xs={12}>
              <Typography className={classes.workspace}>{site}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.titleContainer}>
              <Box className={classes.leftTitleBar} />
              <Typography
                className={clsx([
                  classes.newsTitle,
                  idx === 0 && classes.newsBig,
                ])}
              >
                {title}
              </Typography>
            </Grid>
          </>
        )}

        {showMainImage && (
          <Grid xs={6} className={classes.imageContainer}>
            <img src={image} alt={title} className={classes.mainImg} />
          </Grid>
        )}
        <Grid
          item
          xs={idx === 0 ? 6 : 12}
          container
          spacing={1}
          alignItems="center"
        >
          {idx === 0 && (
            <>
              <Grid item xs={12}>
                <Typography className={classes.workspace}>{site}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.titleContainer}>
                <Box
                  className={
                    idx === 0 ? classes.leftTitleBarLarge : classes.leftTitleBar
                  }
                />
                <Typography
                  className={clsx([
                    classes.newsTitle,
                    idx === 0 && classes.newsBig,
                  ])}
                >
                  {title}
                </Typography>
              </Grid>
            </>
          )}

          <Grid
            item
            xs={12}
            container
            spacing={1}
            alignItems="center"
            className={classes.authorContainer}
          >
            <Grid item>
              <Avatar author={author} customClass={classes.avatarMargin} url={avatarUrl} />
            </Grid>
            <Grid item>
              <Typography className={classes.authorName}>{author}</Typography>
              <Typography className={classes.date}>
                {moment(createdDate)
                  .format("L")
                  .toString()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={clsx([classes.ingress, showSubImage && classes.clamped])}
          >
            {leadText}
          </Typography>
        </Grid>        
        {newsPost && (

          <Grid item xs={12} className={classes.joinNowContainer} >
            <Typography className={classes.joinNow} onClick={n => handleOnClick(news)}>
              <FormattedMessage id="product.readMore" /> <Icon className={clsx(["fas fa-angle-double-right", classes.arrowIcon])} />
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default News;
