import { FormattedMessage } from "react-intl";
import { FormHelperText } from "@mui/material";
import { styled } from "@mui/styles";

const ErrorLabelFormHelperText = styled(FormHelperText)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  borderRadius: 3,
  color: theme.palette.common.white,
  display: "inline-block",
  fontSize: "10px",
  lineHeight: "14px",
  margin: 0,
  opacity: 0.8,
  padding: "0 3px",
  position: "absolute",
  right: 0,
  top: 0,
  zIndex: 1,
  "&.Mui-error": {
    color: theme.palette.common.white,
  },
}));

const ErrorLabel = ({ messageId = "context.required", show }) => (
  show && (
    <ErrorLabelFormHelperText>
      <FormattedMessage id={messageId} />
    </ErrorLabelFormHelperText>
  )
);

export default ErrorLabel;
