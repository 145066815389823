import { InputLabel, Typography } from "@mui/material";

const Text = ({ children, color = undefined }) => (
  <Typography variant="span" sx={{ color }}>
    {children}
  </Typography>
);

const FieldLabel = ({ description, displayName, fieldName, isRequired, shrink, value }) => (
  <InputLabel
    required={isRequired}
    shrink={shrink}
  >
    {value &&
      <Text>
        {displayName || fieldName}
      </Text>
    }
    {description &&
      <Text color="grey">
        {`${value && ' | '}${description}`}
      </Text>
    }
  </InputLabel>
);

export default FieldLabel;
