import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  CheckboxField,
  ImageField,
  InputField,
  SelectField,
} from "./DynamicFieldsComponents/FormControl";

const DynamicField = ({
  error,
  field,
  formValues,
  onChange,
  onCheckboxChange,
  productId,
  setFormValues,
}) => {
  const intl = useIntl();

  const [imageList, setImageList] = useState([]);
  const [textList, setTextList] = useState([]);

  useEffect(() => {
    const { imageList, textList, fieldType } = field;

    if (fieldType === "DROPDOWN_TEXT") {
      const placeholder = [
        {
          label: intl.formatMessage({ id: "product.noOptions" }),
          value: "placeholder",
          disabled: true,
        },
      ];
      if (
        textList &&
        Array.isArray(textList.items) &&
        textList.items.length > 0
      ) {
        setTextList(textList.items);
      } else {
        setTextList(placeholder);
      }
    }

    if (fieldType === "DROPDOWN_IMAGE") {
      const placeholder = [
        {
          label: intl.formatMessage({ id: "product.noOptions" }),
          value: "placeholder",
          disabled: true,
        },
      ];
      if (
        imageList &&
        Array.isArray(imageList.items) &&
        imageList.items.length > 0
      ) {
        setImageList(imageList.items);
      } else {
        setImageList(placeholder);
      }
    }
  }, [field]);

  if (field.fieldType === "TEXT") {
    return (
      <InputField
        description={field.description}
        displayName={field.displayName}
        error={error[field.fieldName]}
        fieldName={field.fieldName}
        isRequired={field.required}
        key={field.fieldName}
        onChange={onChange}
        value={formValues[field.fieldName] || ""}
      />
    );
  }

  if (field.fieldType === "DROPDOWN_TEXT") {
    return (
      <SelectField
        description={field.description}
        displayName={field.displayName}
        error={error[field.fieldName]}
        fieldName={field.fieldName}
        isRequired={field.required}
        key={field.fieldName}
        onChange={onChange}
        options={textList}
        value={formValues[field.fieldName]}
      />
    );
  }

  if (field.fieldType === "DROPDOWN_IMAGE") {
    return (
      <SelectField
        description={field.description}
        displayName={field.displayName}
        error={error[field.fieldName]}
        fieldName={field.fieldName}
        isRequired={field.required}
        key={field.fieldName === "" ? field.displayName : field.fieldName}
        onChange={onChange}
        options={imageList}
        value={formValues[field.fieldName]}
      />
    );
  }

  if (field.fieldType === "IMAGE") {
    return (
      <ImageField
        description={field.description}
        displayName={field.displayName}
        error={error[field.fieldName]}
        fieldName={field.fieldName}
        formValues={formValues}
        imageFolderid={field.imageFolderId}
        isRequired={field.required}
        key={field.fieldName === "" ? field.displayName : field.fieldName}
        productId={productId}
        setFormValues={setFormValues}
        value={formValues[field.fieldName]}
      />
    );
  }

  if (field.fieldType === "TEXT_AREA") {
    return (
      <InputField
        description={field.description}
        displayName={field.displayName}
        error={error[field.fieldName]}
        fieldName={field.fieldName}
        isRequired={field.required}
        key={field.fieldName}
        minRows={4}
        maxRows={6}
        multiline
        onChange={onChange}
        value={formValues[field.fieldName] || ""}
      />
    );
  }

  if (field.fieldType === "CHECKBOX") {
    return (
      <CheckboxField
        displayName={field.displayName}
        fieldName={field.fieldName}
        onChange={onCheckboxChange}
        value={formValues[field.fieldName]}
      />
    );
  }

  return null;
};

DynamicField.defaultProps = {
  error: {},
};

DynamicField.propTypes = {
  error: PropTypes.objectOf(
    PropTypes.shape({
      error: PropTypes.bool,
    })
  ),
  field: PropTypes.shape({
    description: PropTypes.string,
    displayName: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
    imageFolderId: PropTypes.number,
    imageList: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        })
      ).isRequired,
    }),
    required: PropTypes.bool,
    textList: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        })
      ).isRequired,
    }),
  }).isRequired,
  formValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DynamicField;
