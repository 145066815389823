import clsx from "clsx";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Grid,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomSelect from "../../../../../../common/CustomSelect";
import CheckboxDefault from "../../../../../../common/FormControl/Checkbox";
import CustomTextField from "../../../../../../common/TextField";
import { fieldTypes } from "../../helpers";

const useStyles = makeStyles(() => ({
  root: {
    padding: 30,
    background: "#ddd",
  },
  attributesWrapper: {
    padding: 20,
    background: "#f5f5f5",
  },
  fieldLabels: {
    color: "#9A9A9A",
    fontSize: 10,
    display: "inline-flex",
    alignItems: "center",
  },
  customField: {
    background: "#fff",
    color: "#000",
    "&::placeholder": {
      color: "#000",
    },
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
  uppercase: {
    textTransform: "Uppercase",
  },
  checkbox: {
    marginLeft: 5,
  },
  marginRight: {
    marginRight: 7,
  },
  marginTop: {
    marginTop: 17,
  },
}));

const Attributes = ({
  attributeIndex,
  fields,
  handleDeleteField,
  imageListOptions,
  setFields,
  textListOptions,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const {
    description,
    displayName,
    fieldName,
    fieldType,
    fieldValue,
    imageFolderId,
    required,
    textListId,
  } = fields[attributeIndex];

  const showList = (fieldType === "DROPDOWN_TEXT") || (fieldType === "DROPDOWN_IMAGE");

  const handleChange = e => {
    const { value } = e.target;
    const key = e.target.name;
    const tempArr = [...fields];
    tempArr[attributeIndex] = { ...tempArr[attributeIndex], [key]: value };
    setFields(tempArr);
  };

  const handleCheckbox = checked => {
    const tempArr = [...fields];
    tempArr[attributeIndex] = {
      ...tempArr[attributeIndex],
      required: checked,
    };

    setFields(tempArr);
  };

  const handleSelectTextList = e => {
    const { value } = e.target;
    const tempArr = [...fields];
    tempArr[attributeIndex] = { ...tempArr[attributeIndex], textListId: value };
    setFields(tempArr);
  };

  const handleSelectImageList = e => {
    const { value } = e.target;
    const tempArr = [...fields];
    tempArr[attributeIndex] = {
      ...tempArr[attributeIndex],
      imageFolderId: value,
    };
    setFields(tempArr);
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={2} className={classes.attributesWrapper}>
        <Grid item xs={10}>
          <Typography className={classes.uppercase}>
            <strong>
              <FormattedMessage id="editProduct.editFields" />
            </strong>
          </Typography>
          <Typography>
            <FormattedMessage id="editProduct.supplySettings" />
          </Typography>
          <Typography>
            <FormattedMessage id="editProduct.pressSave" />
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            className={classes.iconButtonEdit}
            onClick={handleDeleteField}
          >
            <Icon className={clsx(["fa fa-trash", classes.icon])} />
          </IconButton>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className={classes.fieldLabels}>
            <strong className={classes.marginRight}>
              <FormattedMessage id="editProduct.type" />
            </strong>{" "}
            <FormattedMessage id="editProduct.typeOfField" />
          </Typography>
          <CustomSelect
            name="fieldType"
            value={fieldType}
            options={fieldTypes(intl)}
            onChange={e => handleChange(e)}
            customClass={classes.customField}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            className={clsx([classes.fieldLabels, classes.marginTop])}
          >
            <strong>
              <FormattedMessage id="editProduct.mandatory" />
            </strong>
            <div className={classes.checkbox}>
              <CheckboxDefault
                onChange={e => handleCheckbox(e.target.checked)}
                value={required}
              />
            </div>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.fieldLabels}>
            <strong className={classes.marginRight}>
              <FormattedMessage id="editProduct.name" />
            </strong>{" "}
            <FormattedMessage id="editProduct.labelWhichShown" />
          </Typography>
          <CustomTextField
            name="displayName"
            value={displayName}
            onChange={e => handleChange(e)}
            customClass={classes.customField}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.fieldLabels}>
            <strong className={classes.marginRight}>
              <FormattedMessage id="editProduct.variableName" />
            </strong>{" "}
            <FormattedMessage id="editProduct.usedInternally" />
          </Typography>
          <CustomTextField
            name="fieldName"
            value={fieldName}
            onChange={e => handleChange(e)}
            customClass={classes.customField}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.fieldLabels}>
            <strong className={classes.marginRight}>
              <FormattedMessage id="editProduct.exampleText" />
            </strong>{" "}
            <FormattedMessage id="editProduct.exampleDataForField" />
          </Typography>
          <CustomTextField
            name="description"
            value={description}
            onChange={e => handleChange(e)}
            customClass={classes.customField}
          />
        </Grid>
        {showList && (
          <Grid item xs={12}>
            <Typography className={classes.fieldLabels}>
              <strong className={classes.marginRight}>
                <FormattedMessage id="editProduct.selector" />
              </strong>{" "}
              <FormattedMessage id="editProduct.clickHereTo" />
            </Typography>
            {fieldType === "DROPDOWN_TEXT" && (
              <CustomSelect
                value={textListId}
                options={textListOptions}
                onChange={e => handleSelectTextList(e)}
                customClass={classes.customField}
              />
            )}
            {fieldType === "DROPDOWN_IMAGE" && (
              <CustomSelect
                value={imageFolderId}
                options={imageListOptions}
                onChange={e => handleSelectImageList(e)}
                customClass={classes.customField}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

Attributes.defaultProps = {
  fields: [],
  textListOptions: [],
};

Attributes.propTypes = {
  attributeIndex: PropTypes.number.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      displayName: PropTypes.string,
      fieldName: PropTypes.string,
      fieldType: PropTypes.string,
      fieldValue: PropTypes.string,
      required: PropTypes.bool,
      textListId: PropTypes.number,
    })
  ),
  handleDeleteField: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  textListOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Attributes;
