import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckboxDefault from "../../../common/FormControl/Checkbox";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 12,
    marginRight: 5,
  },
}));

const CheckboxField = ({ displayName, fieldName, onChange, value }) => {
  const classes = useStyles();

  return (
    <Box>
      <Grid container alignItems="center">
        <Typography className={classes.label}>{displayName}</Typography>
        <CheckboxDefault
          name={fieldName}
          onChange={onChange}
          value={value}
        />
      </Grid>
    </Box>
  );
};

CheckboxField.defaultProps = {
  displayName: null,
  error: null,
  value: null,
};

CheckboxField.propTypes = {
  displayName: PropTypes.string,
  error: PropTypes.shape({
    error: PropTypes.bool,
  }),
  fieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
};

export default CheckboxField;
