import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckboxDefault from "../../../../../common/FormControl/Checkbox";
import DateFull from "../../../../../common/FormControl/DateFull";
import CustomTextField from "../../../../../common/TextField";
import TextAreaFull from "../../../../../common/FormControl/TextAreaFull";
import DateActive from "./DateActive";
import { isFieldRequired } from "../helpers";
import ProductInfoTags from "../ProductInfoTags";
import CustomMetadata from "../../CooperationInfo/CustomMetadata";
import { addMetadata } from "../../../../../../actions";
import CustomSelect from "../../../../../common/CustomSelect";

const useStyles = makeStyles(theme => ({
  label: {
    marginTop: 0,
    marginBottom: 3,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  gramLabel: {
    float: "right",
    fontSize: 10,
    marginBottom: 3,
  },
  activeBox: {
    marginTop: 10,
  },
  customCb: {
    "& .MuiButtonBase-root": {
      padding: 0,
      paddingRight: 5,
    },
  },
  // formatWrap: {
  //   alignItems: "flex-end",
  //   marginTop: -3,
  // },
  textFieldLabel: {
    color: "#000",
    fontSize: "11px!important",
    fontWeight: 600,
  },
  subLabelContainer: {
    position: "relative",
  },
  subLabel: {
    position: "absolute",
    top: -12,
    right: 6,
  },
}));

const FormSection = ({
  formValues,
  handleCheckbox,
  handleFromDateChange,
  handleToDateChange,
  handleFormValues,
  errors,
  selectedProductType,
  handleSelectProductType,
  productTypeOptions,
  handleImageMetadata,
  handleTags,
  handleMetadataDate,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { imageMetadata = {} } = formValues || {};

  console.log("formValues >", formValues);

  const handleAddCustomMetadata = () => {
    const defaultValues = { display: true, label: "", value: "" };
    dispatch(addMetadata({ value: defaultValues }));
  };

  const editPriceDescPlaceholder = intl.formatMessage({
    id: "product.editPriceDescPlaceholder",
  });

  const renderRequiredMark = fieldName => {
    const isRequired = isFieldRequired(fieldName, selectedProductType);

    if (isRequired) {
      return "*";
    }
    return null;
  };

  const handleFocus = event => event.target.select();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Box className={classes.activeBox}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6}>
              <CustomSelect
                options={productTypeOptions}
                value={selectedProductType}
                onChange={handleSelectProductType}
                shrink
                inputLabel={<FormattedMessage id="product.productType" />}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                label={<FormattedMessage id="product.editActive" />}
                control={
                  <Switch
                    name="active"
                    checked={formValues.active}
                    onChange={handleCheckbox}
                    color="primary"
                  />
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <CustomTextField
              name="name"
              label={<FormattedMessage id="product.name" />}
              required
              value={formValues.name}
              onChange={handleFormValues}
              error={formValues.name ? false : errors.name || false}
              helperText={formValues.name ? null : errors.name}
              customLabelClass={classes.textFieldLabel}
              shrink
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <CustomTextField
              name="articleNo"
              required={false}
              label={<FormattedMessage id="product.id" />}
              value={formValues.articleNo}
              onChange={handleFormValues}
              customLabelClass={classes.textFieldLabel}
              shrink
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextAreaFull
              placeholder={editPriceDescPlaceholder}
              rows="6"
              rowsMax="6"
              label={<FormattedMessage id="product.description" />}
              name="description"
              value={formValues.description}
              onChange={handleFormValues}
              customLabelClass={classes.textFieldLabel}
              shrink
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextAreaFull
              placeholder={intl.formatMessage({
                id: "product.detailedDescription",
              })}
              rows="3"
              rowsMax="3"
              value={formValues.detailedDescription}
              name="detailedDescription"
              onChange={handleFormValues}
              customLabelClass={classes.textFieldLabel}
              label={<FormattedMessage id="product.detailedDescription" />}
              shrink
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <ProductInfoTags
              label={<FormattedMessage id="product.taggingInfo" />}
              tags={formValues.tags || []}
              setTags={handleTags}
              shrink
              customLabelClass={classes.textFieldLabel}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={1}>
          <DateActive
            formValues={formValues}
            handleFromDateChange={handleFromDateChange}
            handleToDateChange={handleToDateChange}
            customLabelClass={classes.textFieldLabel}
          />
          <Grid item xs={12} md={6}>
            <CustomTextField
              value={formValues.format}
              name="format"
              onChange={handleFormValues}
              label={<FormattedMessage id="product.format" />}
              customLabelClass={classes.textFieldLabel}
              shrink
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              value={formValues.weight}
              name="weight"
              onChange={handleFormValues}
              type="number"
              onFocus={handleFocus}
              label={<FormattedMessage id="product.weight" />}
              customLabelClass={classes.textFieldLabel}
              shrink
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={12} md={6}>
                <CustomTextField
                  value={formValues.material}
                  name="material"
                  onChange={handleFormValues}
                  label="Material"
                  customLabelClass={classes.textFieldLabel}
                  shrink
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* <SelectNativeFull
                      options={[
                        { label: "Ingen lista", value: 1 },
                        { label: "Avdelning", value: 2 }
                      ]}
                    /> */}
                <CustomTextField
                  value={formValues.unit}
                  name="unit"
                  onChange={handleFormValues}
                  customLabelClass={classes.textFieldLabel}
                  label={<FormattedMessage id="product.orderUnit" />}
                  shrink
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              value={formValues.pageRange}
              name="pageRange"
              onChange={handleFormValues}
              type="number"
              onFocus={handleFocus}
              label={<FormattedMessage id="product.pageRange" />}
              customLabelClass={classes.textFieldLabel}
              shrink
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <CheckboxDefault
                name="isFreeToUser"
                value={imageMetadata ? imageMetadata.isFreeToUser : false}
                onChange={handleImageMetadata}
              />
              <FormattedMessage id="product.reedemed" />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              value={imageMetadata ? imageMetadata.location : ""}
              name="location"
              onChange={handleImageMetadata}
              onFocus={handleFocus}
              label={<FormattedMessage id="product.location" />}
              customLabelClass={classes.textFieldLabel}
              shrink
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateFull
              allowNull
              customLabelClass={classes.textFieldLabel}
              label={intl.formatMessage({
                id: "product.date",
              })}
              name="date"
              setDate={handleMetadataDate}
              shrink
              value={imageMetadata?.date}
              variant="dialog"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CustomTextField
              value={imageMetadata ? imageMetadata.model : ""}
              name="model"
              onChange={handleImageMetadata}
              onFocus={handleFocus}
              label={<FormattedMessage id="product.model" />}
              customLabelClass={classes.textFieldLabel}
              shrink
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              value={imageMetadata ? imageMetadata.occupation : ""}
              name="occupation"
              onChange={handleImageMetadata}
              onFocus={handleFocus}
              label={<FormattedMessage id="product.occupation" />}
              customLabelClass={classes.textFieldLabel}
              shrink
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomMetadata handleAddCustomMetadata={handleAddCustomMetadata} />
      </Grid>
    </Grid>
  );
};

FormSection.defaultProps = {
  formValues: {},
  selectedProductType: "",
};

FormSection.propTypes = {
  errors: PropTypes.shape({ name: PropTypes.string }).isRequired,
  formValues: PropTypes.shape({
    active: PropTypes.string,
    activeFromDate: PropTypes.instanceOf(Date),
    activeUntilDate: PropTypes.instanceOf(Date),
    articleNo: PropTypes.string,
    description: PropTypes.string,
    detailedDescription: PropTypes.string,
    format: PropTypes.string,
    material: PropTypes.string,
    name: PropTypes.string,
    pageRange: PropTypes.number,
    resourceId: PropTypes.string,
    showFormat: PropTypes.bool,
    unit: PropTypes.number,
    weight: PropTypes.number,
  }),
  handleFormValues: PropTypes.func.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  handleFromDateChange: PropTypes.func.isRequired,
  handleToDateChange: PropTypes.func.isRequired,
  selectedProductType: PropTypes.string,
};

export default FormSection;
