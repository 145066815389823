import { gql } from '@apollo/client';
import SiteNewsComment from "./SiteNewsComment";

const SiteNewsItem = gql`
  fragment SiteNewsItemProperties on SiteNewsItem {
    comments {
      ...SiteNewsCommentProperties
    }
    allowComments
    author
    avatarUrl
    category
    createdDate
    id
    image
    leadText
    siteId
    siteId
    template
    thumbnail
    title
    viewCount
    __typename
  }
  ${SiteNewsComment}
`;

export default SiteNewsItem;
