import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { Box, Button, Grid } from "@mui/material";
import { FOLDERS_FOR_DYNAMIC_TEMPLATE_FIELD } from "../../../../graphql/queries";
import DraggableModal from "../../../common/DraggableModal";
import DraggableModalBody from "../../../common/DraggableModal/DraggableModalBody";
import DraggableModalHead from "../../../common/DraggableModal/DraggableModalHead";
import FolderTreeContainer from "../../../common/FolderTreeContainer";
import SearchField from "../../../common/SearchField";
import TreeSkeleton from "../../../Skeleton/TreeSkeleton";
import ResourcesSection from "./ResourcesSection";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "900px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalHeader: {
    padding: 10,
    color: "#fff",
    minHeight: 20,
    "& .MuiIconButton-root": {
      marginLeft: 10,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  modalBody: {
    padding: 0,
    minHeight: 300,
    display: "flex",
    flexDirection: "column",
  },
  borderRight: {
    borderRight: "1px solid #dee2e6",
  },
  btn: {
    padding: "5px 15px",
    fontWeight: 600,
    fontSize: 12,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 5,
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
  modalFooter: {
    borderTop: "1px solid #ddd",
    padding: "10px 15px 20px 15px",
  },
  modalScroll: {
    overflowY: "auto",
    flex: 1,
    display: "flex",
  },
  siteSelect: {
    textAlign: "center",
    padding: "40px 0",
  },
  emptyText: {
    fontSize: 12,
    marginTop: 10,
    color: theme.palette.text.secondary,
  },
}));

const PickImageFieldModal = ({
  handleClose,
  handleImageSelect,
  imageField,
  imageFolderid,
  productId,
  open,
}) => {
  const classes = useStyles();

  const [workspace, setWorkspace] = useState({ id: 18 });
  const [folderId, setFolderId] = useState(null);
  const intl = useIntl();
  const [searchText, setSearchText] = useState("");
  const [folders, setFolders] = useState([]);

  const { data, loading } = useQuery(FOLDERS_FOR_DYNAMIC_TEMPLATE_FIELD, {
    variables: {
      productId,
      imageFolderid,
    },
    skip: !productId || !imageFolderid,
  });

  const handleOnChange = event => {
    if (event.target.value) {
      setSearchText(event.target.value);
    }
  };

  const handleCloseModal = () => {
    setSearchText("");
    handleClose();
  };

  useEffect(() => {
    if (data && data.foldersForDynamicTemplateField) {
      setFolders([data.foldersForDynamicTemplateField]);
    }
  }, [data, loading]);

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={intl.formatMessage({
          id: "common.chooseImage",
        })}
        customClass={classes.modalHeader}
      >
        <SearchField
          searchText={searchText}
          handleOnChange={handleOnChange}
          siteId={workspace && workspace.id ? workspace.id : null}
        />
      </DraggableModalHead>
      <DraggableModalBody customClass={classes.modalBody}>
        <>
          <div className={classes.modalScroll}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={3} className={classes.borderRight}>
                <Box px={1} py={2}>
                  {loading && <TreeSkeleton />}
                  {!loading && folders && folders.length > 0 && (
                    <FolderTreeContainer
                      folders={folders}
                      setFolderId={setFolderId}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <ResourcesSection
                  folderId={folderId}
                  productId={productId}
                  handleImageSelect={handleImageSelect}
                  imageField={imageField}
                />
              </Grid>
            </Grid>
          </div>
          <Grid
            container
            justifyContent="flex-end"
            direction="row"
            className={classes.modalFooter}
          >
            <Button
              className={classes.btn}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleCloseModal()}
            >
              <FormattedMessage id="btn.close" />
            </Button>
          </Grid>
        </>
      </DraggableModalBody>
    </DraggableModal>
  );
};

PickImageFieldModal.defaultProps = {};

PickImageFieldModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleImageSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(memo(PickImageFieldModal));
