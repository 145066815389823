import { useState } from "react";
import { FormattedMessage } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ButtonPrimaryAltSm from "../../../common/ButtonPrimaryAltSm";
import PickImageFieldModal from "../PickImageFieldModal";
import { ErrorLabel, FieldLabel } from "./Labels";

const useStyles = makeStyles(theme => ({
  uploadBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    marginBottom: 5,
  },
  formControl: {
    "& .MuiInputLabel-shrink": {
      fontSize: 12,
      top: 10,
    },
    "& .MuiOutlinedInput-root": {
      paddingBottom: 0,
      paddingRight: 0,
    },
    "& .MuiOutlinedInput-input": {
      paddingBottom: 2,
    },
  },
}));

const ImageField = ({
  description,
  displayName,
  error,
  fieldName,
  formValues,
  imageFolderid,
  isRequired,
  productId,
  setFormValues,
  value,
}) => {
  const classes = useStyles();

  const haveError = !!error?.error;

  const [imageField, setImageField] = useState({});
  const [pickModalVisible, setPickModalVisible] = useState(false);

  const handleImageSelect = item => {
    setFormValues({
      ...formValues,
      [fieldName]: item?.id || "",
    });
    setImageField(item);
  };

  const handleRemoveImageField = () => {
    setImageField({});
    setFormValues({
      ...formValues,
      [fieldName]: "",
    });
  };

  return (
    <Box className={classes.uploadBox}>
      <FormControl className={classes.formControl} fullWidth>
        <FieldLabel
          description={description.replace("[", "").replace("]", "")}
          displayName={displayName}
          error={haveError}
          fieldName={fieldName}
          //isRequired={isRequired}
          shrink
          value={value}
        />
        <TextField
          //error={haveError}
          InputProps={{
            endAdornment:
              <InputAdornment position="end" sx={{ margin: 0 }}>
                <ErrorLabel show={haveError} />
                {!haveError &&
                  <IconButton size="small" onClick={handleRemoveImageField}>
                    <CloseIcon />
                  </IconButton>
                }
              </InputAdornment>
          }}
          name="imageField"
          value={imageField && imageField.id && imageField.name || ""}
          variant="outlined"
        />
      </FormControl>
      <ButtonPrimaryAltSm
        label={<FormattedMessage id="common.chooseImage" />}
        onClick={() => setPickModalVisible(true)}
        sx={{ whiteSpace: "nowrap" }}
      />
      <PickImageFieldModal
        handleClose={() => setPickModalVisible(false)}
        handleImageSelect={handleImageSelect}
        imageField={imageField}
        imageFolderid={imageFolderid}
        open={pickModalVisible}
        productId={productId}
      />
    </Box>
  );
};

export default ImageField;
