import { gql } from '@apollo/client';

const GET_NEWS_FOR_USER = gql`
  query GetNewsForUser($offset: Int!, $pageSize: Int!) {
    newsContext {
      getNewsForUser(offset: $offset, pageSize: $pageSize) {
        author
        avatarUrl
        category
        createdDate
        id
        image
        itemType
        leadText
        site
        thumbnail
        title
        viewCount
        newsPost
      }
    }
  }
`;

export default GET_NEWS_FOR_USER;
