import PropTypes from "prop-types";
import { FormControl, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ErrorLabel, FieldLabel } from "./Labels";

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: 5,
    "& .MuiInputLabel-shrink": {
      fontSize: 12,
      top: 10,
    },
    "& .MuiOutlinedInput-root": {
      paddingBottom: 0,
      paddingRight: 0,
    },
    "& .MuiOutlinedInput-input": {
      paddingBottom: 2,
    },
  },
}));

const SelectField = ({
  description,
  displayName,
  error,
  fieldName,
  isRequired,
  onChange,
  options,
  value,
}) => {
  const classes = useStyles();

  const haveError = !!error?.error;

  return (
    <FormControl className={classes.formControl} fullWidth>
      <FieldLabel
        description={description.replace("[", "").replace("]", "")}
        displayName={displayName}
        error={haveError}
        fieldName={fieldName}
        //isRequired={isRequired}
        shrink
        value={value}
      />
      <Select
        //error={haveError}
        endAdornment={
          <ErrorLabel show={haveError} />
        }
        name={fieldName}
        onChange={onChange}
        value={value || ""}
      >
        {options.map(option => (
          <MenuItem
            disabled={option.disabled}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectField.defaultProps = {
  description: "",
  displayName: null,
  error: null,
  isRequired: false,
};

SelectField.propTypes = {
  description: PropTypes.string,
  displayName: PropTypes.string,
  error: PropTypes.shape({
    error: PropTypes.bool,
  }),
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default SelectField;
