import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { DYNAMIC_TEMPLATE_IMAGES } from "../../../../graphql/queries";
import ResourcesHeader from "../../../common/SelectFileFromSystemModal/ResourcesHeader";
import PickImageFieldSkeleton from "../../../Skeleton/PickImageFieldSkeleton";
import ResourcesList from "./ResourcesList";

const ResourcesSection = ({
  folderId,
  handleImageSelect,
  imageField,
  productId,
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25); // !!!
  const [resources, setResources] = useState([]);
  const [ascending, setAscending] = useState(false);
  const [sortfield, setSortfield] = useState("Name");
  const [sortFieldOptions, setSortFieldOptions] = useState([
    { value: "Name", label: "Name" },
  ]);
  const [resourcesTotalCount, setResourcesTotalCount] = useState(0);

  const handlePageSizeChange = e => {
    setPage(0);
    setPageSize(e.target.value);
  };

  const { data, loading, error } = useQuery(DYNAMIC_TEMPLATE_IMAGES, {
    variables: {
      productId,
      folderId,
      // TODO:
      // Add sorting and pagination next time.
      // sortfield,
      // ascending
      // offset: page * pageSize,
      pageSize,
    },
    skip: !folderId || !productId,
  });

  useEffect(() => {
    if (
      !loading &&
      !error &&
      data &&
      data.dynamicTemplateImages &&
      data.dynamicTemplateImages.items
    ) {
      setResources(data.dynamicTemplateImages.items);
      setResourcesTotalCount(data.dynamicTemplateImages.totalCount || 0);
    }
    if (error) {
      setResources([]);
      setResourcesTotalCount(0);
    }
  }, [data, loading, error]);

  return (
    <Box p={2}>
      <ResourcesHeader
        ascending={ascending}
        onPageChange={(e, p) => setPage(p)}
        onPageSizeChange={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        setAscending={setAscending}
        setSortfield={setSortfield}
        sortfield={sortfield}
        sortFieldOptions={sortFieldOptions}
        totalCount={resourcesTotalCount}
      />
      {!loading && Array.isArray(resources) && (
        <ResourcesList
          resources={resources}
          handleImageSelect={handleImageSelect}
          imageField={imageField}
        />
      )}

      {loading && <PickImageFieldSkeleton />}
    </Box>
  );
};

export default ResourcesSection;
